<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <div
        class="template-row"
        v-for="template in templates"
        :key="template.idtemplate"
      >
        <div class="template-info">
          <div class="line-visible">
            <div class="template-title">{{ template.titulo }}</div>
            <div class="template-descricao" v-html="template.descricao"></div>
          </div>
          <div
            class="line-hidden"
            :class="{ visible: openTemplate[template.nome] }"
          >
            <div class="variaveis-title">
              Variáveis
            </div>
            <small>
              Use das variáveis para incluir dados do banco de dados nas suas
              mensagens. Dentro do template inclua as variáveis como um texto e
              no envio estas serão alteradas para um dado real.</small
            >
            <ul class="variable-list">
              <li
                class="variable-item"
                v-for="(value, key) in template.variaveis"
                :key="key"
              >
                <span>{{ templateVar(key) }}</span> - {{ value }}
              </li>
            </ul>
          </div>
          <div class="show-btn" v-if="hasVariaveis(template.variaveis)">
            <p @click="showVariaveis(template.nome)">Variáveis</p>
            <i
              class="fas fa-chevron-down"
              @click="showVariaveis(template.nome)"
            ></i>
          </div>
        </div>
        <div class="template-preview">
          <div class="template-img" v-if="templateSelecionado[template.nome]">
            <img :src="templateImage(template.nome)" />

            <div
              class="overview-template"
              @click="
                editaTemplate(
                  template.nome,
                  template.titulo,
                  template.descricao
                )
              "
            >
              <i class="fas fa-edit"></i>
            </div>
          </div>
          <p
            @click="
              editaTemplate(template.nome, template.titulo, template.descricao)
            "
            v-if="templateSelecionado[template.nome]"
            class="btn-template"
          >
            Escolher outro template
          </p>
          <p
            @click="removerTemplate(template.nome)"
            v-if="templateSelecionado[template.nome]"
            class="btn-template"
          >
            Remover template
          </p>
          <FormButton
            @click="
              editaTemplate(template.nome, template.titulo, template.descricao)
            "
            v-if="!templateSelecionado[template.nome]"
            text="Selecionar"
            type="secundario"
          />
        </div>
      </div>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <SidePopup
        title="Seleção de template"
        v-on:close="onCloseSelection"
        overlayNotBack
        v-if="formSelection.open"
      >
        <div class="template-title">{{ formSelection.titulo }}</div>
        <div class="template-descricao" v-html="formSelection.descricao"></div>
        <LineDivider position="bottom">
          <h4>Templates disponíveis no ElasticMail</h4>
        </LineDivider>
        <div class="template-selecao-list">
          <div
            class="template-selecao"
            v-for="item in getTemplateElastic"
            :key="item.nome"
          >
            <div class="template-selecao-info">
              <div class="template-selecao-prev">
                <img :src="item.screenshot" />
              </div>
              <div class="template-selecao-name">{{ item.nome }}</div>
              <div class="template-selecao-remetente" v-if="item.fromemail">
                Remetente: {{ item.fromname }}
                <small>({{ item.fromemail }})</small>
              </div>
              <div class="template-selecao-remetente sem" v-else>
                Sem remetente
              </div>
              <div class="overview-select" @click="selecionaTemplate(item)">
                Selecionar
              </div>
            </div>
            <a
              class="editar-elasticmail"
              target="_blank"
              :href="item.linkedicaotemplate"
              >Editar no ElasticMail</a
            >
          </div>
        </div>
      </SidePopup>
    </transition>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import {
  MARKETING_LOAD_TEMPLATES,
  MARKETING_LOAD_TEMPLATES_SELECTED,
  MARKETING_UPDATE_TEMPLATES_SELECTED,
  MARKETING_EMAIL_CONFIG,
  MAKRETING_LOAD_TEMPLATE_ELASTIC
} from '@/store/actions/marketing'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import FormButton from '@/components/form/FormButton'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'TemplateEmail',
  components: { Panel, SidePopup, LineDivider, FormButton, MarketingModules },
  data: () => ({
    templates: [],
    templateSelecionado: {},
    openTemplate: {},
    formSelection: {
      tipo: '',
      template: '',
      open: false
    },
    modulosForm: [
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getEmailService',
      'getTemplateElastic',
      'moduloFornecedorAtivo'
    ]),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  watch: {
    getFornecedorId: {
      handler() {
        this.loadTemplateSelecao()
        this.loadEmailConfig()
      }
    }
  },
  created() {
    this.loadTemplates()
    this.loadEmailConfig()
    this.loadTemplateSelecao()
    this.loadTemplateElastic()
  },
  methods: {
    loadTemplates() {
      this.$store.dispatch(MARKETING_LOAD_TEMPLATES).then(templates => {
        this.templates = templates || []
      })
    },
    loadEmailConfig() {
      this.$store.dispatch(MARKETING_EMAIL_CONFIG)
    },
    loadTemplateSelecao() {
      this.$store.dispatch(MARKETING_LOAD_TEMPLATES_SELECTED).then(resp => {
        this.templateSelecionado = resp
      })
    },
    loadTemplateElastic() {
      this.$store.dispatch(MAKRETING_LOAD_TEMPLATE_ELASTIC)
    },
    saveSelecao() {
      this.$store
        .dispatch(MARKETING_UPDATE_TEMPLATES_SELECTED, this.templateSelecionado)
        .then(() => {
          this.$vueOnToast.pop('success', 'Template salvo com sucesso')
        })
    },
    templateVar(variable) {
      return `{${variable}}`
    },
    templateImage(templateName) {
      return _.get(this.templateSelecionado, [templateName, 'screenshot'], '')
    },
    showVariaveis(templateName) {
      this.openTemplate = {
        ...this.openTemplate,
        [templateName]: !(this.openTemplate[templateName] || false)
      }
    },
    hasVariaveis(variaveis) {
      return variaveis && Object.keys(variaveis).length > 0
    },
    editaTemplate(templateName, titulo, descricao) {
      this.formSelection = {
        open: true,
        tipo: templateName,
        template: '',
        titulo,
        descricao
      }
    },
    removerTemplate(templateName) {
      this.templateSelecionado = {
        ...this.templateSelecionado,
        [templateName]: ''
      }
      this.saveSelecao()
    },
    selecionaTemplate(template) {
      this.templateSelecionado = {
        ...this.templateSelecionado,
        [this.formSelection.tipo]: template
      }
      this.formSelection = {
        open: false,
        tipo: '',
        titulo: '',
        descricao: ''
      }
      this.saveSelecao()
    },
    onCloseSelection() {
      this.formSelection = {
        open: false,
        tipo: '',
        titulo: '',
        descricao: ''
      }
    }
  }
}
</script>

<style>
.template-row {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e4e5e7;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.template-info {
  flex: 1;
  max-width: 100%;
  min-width: 300px;
  position: relative;
  padding-bottom: 50px;
}
.template-preview {
  flex: 1;
  max-width: 200px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.template-preview p {
  font-size: 12px;
  color: #6a6c6f;
}
.template-preview .template-img {
  width: 120px;
  height: 120px;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
.template-preview .template-img img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.template-preview .template-img .overview-template {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
}
.template-preview .template-img:hover .overview-template {
  opacity: 1;
}
.template-preview .template-img .overview-template i {
  cursor: pointer;
}
.line-visible {
  width: 100%;
  text-align: left;
}
.template-title {
  width: 100%;
  font-size: 18px;
}
.template-descricao {
  width: 100%;
  font-size: 14px;
  color: #6a6c6f;
}
.line-hidden {
  width: 100%;
  text-align: left;
  padding: 10px 0;
  opacity: 0;
  height: 0;
  transition: all 0.3s ease-in-out;
}
.line-hidden .variaveis-title {
  font-size: 14px;
  font-weight: bold;
}
.line-hidden.visible {
  opacity: 1;
  height: inherit;
}
.show-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 5px;
  color: #1976d2;
}
.show-btn p {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}
.show-btn i {
  font-size: 15px;
  cursor: pointer;
}
.variable-list li span {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
.template-selecao-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.template-selecao-list .template-selecao {
  flex: 1;
  max-width: 350px;
  min-width: 250px;
  padding: 10px;
  margin: 5px;
  position: relative;
}
.template-selecao .template-selecao-prev {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}
.template-selecao .template-selecao-prev img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.template-selecao .template-selecao-name {
  width: 100%;
  font-size: 16px;
}
.template-selecao .template-selecao-remetente,
.editar-elasticmail {
  font-size: 14px;
}
.template-selecao-remetente.sem {
  color: var(--danger-color);
  font-weight: bold;
}
.template-selecao .overview-select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.template-selecao .template-selecao-info:hover .overview-select {
  opacity: 1;
}
.template-selecao .template-selecao-info {
  width: 100%;
  position: relative;
}
.btn-template {
  cursor: pointer;
}
</style>
